// create an api with 20 mock records (no duplicates) of customers with the following fields: id, FirstName, LastName, PhoneNumber, ServiceEndTime, Date, Location, Agent

export const customers = [
  {
    id: 1,
    FirstName: "John",
    LastName: "Smith",
    PhoneNumber: "123-456-7890",
    ServiceEndTime: "2:00pm",
    Date: "10/10/2021",
    Location: "PNC Bank",
    Agent: "Agent 1"
  },
  {
    id: 2,
    FirstName: "Jane",
    LastName: "Doe",
    PhoneNumber: "123-456-7890",
    ServiceEndTime: "2:00pm",
    Date: "10/10/2021",
    Location: "PNC Bank",
    Agent: "Agent 1"
  },
  {
    id: 3,
    FirstName: "John",
    LastName: "Smith",
    PhoneNumber: "123-456-7890",
    ServiceEndTime: "2:00pm",
    Date: "10/10/2021",
    Location: "PNC Bank",
    Agent: "Agent 1"
  },
  {
    id: 4,
    FirstName: "Jane",
    LastName: "Doe",
    PhoneNumber: "123-456-7890",
    ServiceEndTime: "2:00pm",
    Date: "10/10/2021",
    Location: "PNC Bank",
    Agent: "Agent 1"
  },
  {
    id: 5,
    FirstName: "John",
    LastName: "Smith",
    PhoneNumber: "123-456-7890",
    ServiceEndTime: "2:00pm",
    Date: "10/10/2021",
    Location: "PNC Bank",
    Agent: "Agent 1"
  },
  {
    id: 6,
    FirstName: "Jane",
    LastName: "Doe",
    PhoneNumber: "123-456-7890",
    ServiceEndTime: "2:00pm",
    Date: "10/10/2021",
    Location: "PNC Bank",
    Agent: "Agent 1"
  },
  {
    id: 1,
    FirstName: "John",
    LastName: "Smith",
    PhoneNumber: "123-456-7890",
    ServiceEndTime: "2:00pm",
    Date: "10/10/2021",
    Location: "PNC Bank",
    Agent: "Agent 1"
  },
  {
    id: 2,
    FirstName: "Jane",
    LastName: "Doe",
    PhoneNumber: "123-456-7890",
    ServiceEndTime: "2:00pm",
    Date: "10/10/2021",
    Location: "PNC Bank",
    Agent: "Agent 1"
  },
  {
    id: 3,
    FirstName: "John",
    LastName: "Smith",
    PhoneNumber: "123-456-7890",
    ServiceEndTime: "2:00pm",
    Date: "10/10/2021",
    Location: "PNC Bank",
    Agent: "Agent 1"
  },
  {
    id: 4,
    FirstName: "Jane",
    LastName: "Doe",
    PhoneNumber: "123-456-7890",
    ServiceEndTime: "2:00pm",
    Date: "10/10/2021",
    Location: "PNC Bank",
    Agent: "Agent 1"
  },
  {
    id: 5,
    FirstName: "John",
    LastName: "Smith",
    PhoneNumber: "123-456-7890",
    ServiceEndTime: "2:00pm",
    Date: "10/10/2021",
    Location: "PNC Bank",
    Agent: "Agent 1"
  },
  {
    id: 6,
    FirstName: "Jane",
    LastName: "Doe",
    PhoneNumber: "123-456-7890",
    ServiceEndTime: "2:00pm",
    Date: "10/10/2021",
    Location: "PNC Bank",
    Agent: "Agent 1"
  } ]
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../../assets/css/date-picker-overrides.css';

import React, { useState } from 'react';

import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import {
  Link,
  useHistory,
} from 'react-router-dom';

import MainCard from '@alliancesafetycouncil/asc-card';
import PageTitle from '@alliancesafetycouncil/asc-page-title';

import Logo from '../../assets/img/fast-pass-logo.jpg';
import { Input } from '../../components/Form/Input';
import UserProfile from '../../components/UserProfile/UserProfile';
import { customers } from '../../data/customers';

const options = [
  { item: "Lagos" },
  { item: "Abuja" },
  { item: "Port Harcourt" },
  { item: "Ibadan" },
];

const Report = () => {
  const FSLogo = (e) => {
    return <img src={Logo} alt="" className="w-36" />;
  };

  const history = useHistory();

  const goBack = () => {
    history.goBack(); // Go back to the previous view
  };

  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [focusedInput, setFocusedInput] = React.useState();

  const isOutsideRange = () => false;

  return (
    <div className="flex flex-col h-screen bg-blue-50">
      {/* Main Header wrapper */}
      <div className="fixed z-10 w-full bg-white border-b border-gray-200">
        <div className="flex mx-4 my-4 flex-column">
          <div className="flex-grow">
            <FSLogo />
          </div>
          <div className="flex items-center">
            <UserProfile />
          </div>
        </div>
      </div>

      {/* Main Card wrapper */}
      <div className="flex-auto m-4 mt-24 row content">
        <div className="flex flex-row mb-3">
          <div>
            <PageTitle>
              <div className="flex flex-row items-center">
                <i class="fal fa-file-chart-pie text-2xl text-orange-500 mr-2"></i>
                <div className="text-black">Report</div>
                <Link
                  className="flex flex-row items-center text-base text-blue-500"
                  onClick={goBack}
                  to=""
                >
                  <div className="ml-5">
                    <i class="fal fa-long-arrow-left"></i>
                  </div>
                  <div className="ml-1">Back</div>
                </Link>
              </div>
            </PageTitle>
          </div>
        </div>
        <MainCard type="main">
          {/* Report Grid */}
          <div className="flex flex-col">
            <div className="flex flex-row items-center gap-3">
              <div>
                <Input type="text" name="name" id="name" placeholder="Customer Name" />
              </div>
              <div className="col-span-2">
                <DateRangePicker
                  startDate={startDate}
                  startDateId="start-date"
                  endDate={endDate}
                  endDateId="end-date"
                  minimumNights={0}
                  isOutsideRange={isOutsideRange}
                  onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                />
              </div>
              {/* <div> */}
                {/* list location names for options from the locations data file in the dropdown */}
                {/* <Dropdown options={options} /> */}
              {/* </div> */}
            </div>

            <div className="flex flex-col">
              {/* Header */}
              <div className="grid items-center h-16 grid-cols-6 gap-3 px-3 mt-4 font-medium text-gray-700 bg-blue-50">
                <div>Customer Name</div>
                <div>Phone Number</div>
                <div>Service End Time</div>
                <div>Date</div>
                <div>Location</div>
                <div>Agent</div>
              </div>

              {customers.map((customer, index) => (
                // tailwind make even rows gray
                <div
                  key={customer.id}
                  className={`grid grid-cols-6 gap-3 px-3 h-12 items-center ${
                    index % 2 === 1 ? "bg-gray-100" : "bg-white"
                  }`}
                >
                  <div>
                    {customer.FirstName} {customer.LastName}
                  </div>
                  <div>{customer.PhoneNumber}</div>
                  <div>{customer.ServiceEndTime}</div>
                  <div>{customer.Date}</div>
                  <div>{customer.Location}</div>
                  <div>{customer.Agent}</div>
                </div>
              ))}
            </div>
          </div>
        </MainCard>
      </div>
    </div>
  );
};

export default Report;

// Package Dependencies
import { combineReducers } from 'redux'
// Additional Dependencies
import AppointmentReducer from './Appointments/Transition'
import AppointmentsReducer from './Appointments/Appointments'
import LocationReducer from './Locations/Location'
import LocationsReducer from './Locations/Locations'
// Combine Reducers
const RootReducer = combineReducers({
  appointmentReducer: AppointmentReducer,
  appointmentsReducer: AppointmentsReducer,
  locationReducer: LocationReducer,
  locationsReducer: LocationsReducer
})
// Export
export default RootReducer

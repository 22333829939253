// Package Dependencies
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

// Action Dependencies
import {
  RETRIEVE_LOCATIONS_REQUEST,
  retrieveLocationsError,
  retrieveLocationsResponse,
} from '../../action/Locations/Locations';
// Additional Dependencies
import axios from '../../utility/Axios';

// Function to Get Location UUID
function getLocationUUID() {
  const afterHash = window.location.href.split("#")[1];
  const params = new URLSearchParams(afterHash.split("?")[1]);
  const location_uuid = params.get("location_uuid");

  return location_uuid
}

// Function Handle Locations
function* handleLocations(data) {
  try {
    // Define Query Parameters
    const type = 'window'
    const state = 'active'
    const location_uuid = getLocationUUID()
    // Retrieve Locations Request
    const response = yield call(axios.get_request, `/location?type=${type}&state=${state}&location_uuid=${location_uuid}`)
    // Retrieve Locations Response
    yield put(retrieveLocationsResponse(response.data))
  } catch (error) {
    // Retrieve Locations Error
    yield put(retrieveLocationsError(error))
  }
}

// Export
export const LocationsSaga = [
  takeLatest(RETRIEVE_LOCATIONS_REQUEST, handleLocations),
]